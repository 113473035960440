import './App.css';
import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';

import Auth0Callback from './components/auth/Auth0Callback';
// import CompleteSignup from './components/signup/CompleteSignup';
import SignUp from './components/signup/SignUp';
import LoginPage from './components/LoginPage';
import AuthHandler from './components/auth/AuthHandler';
import AuthorizedPage from './components/AuthorizedPage';
import PrivateRoute from './components/auth/PrivateRoute';

import Header from './components/Header';
import Footer from './components/Footer';
import LanguageLearning from './components/LanguageLearning';
import ProfileSettings from './components/ProfileSettings';

const login_domain = "login.activatedhuman.earth";
const clientId = "yPwZdBlXjR3hP0zHMUVA1iaOmi0E2WoB";


function App() {
  return (
    <Auth0Provider
      domain={login_domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/auth0-callback`,
        audience: `https://${login_domain}/api/v2/`,
        scope: "openid profile email"
      }}
    >
      <Router>
        <AppContent />
      </Router>
    </Auth0Provider>
  );
}

function AppContent() {
  return (
    <div className="App font-sans text-gray-900">
      <Header />
      <AuthHandler />
      <Routes>
        <Route path="/" element={<PrivateRoute component={LanguageLearning} />} />
        <Route path="/profile-settings" element={<PrivateRoute component={ProfileSettings} />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUp />} />
        {/* <Route path="/complete-signup/:token" element={<CompleteSignup />} /> */}
        <Route path="/auth0-callback" element={<Auth0Callback />} />
        <Route path="/authorized" element={<PrivateRoute component={AuthorizedPage} />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;